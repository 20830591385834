/* You can add global styles to this file, and also import other style files */
@import '~@xpo-ltl/ngx-ltl-core/styles/variables';

mat-form-field.no-border {
  .mat-form-field-wrapper > .mat-form-field-flex {
    border: none;
  }
}

.mat-snack-bar-container.warn {
  background-color: $xpo-yellow--350;
}

xpo-exemptions {
  .mat-form-field-label-wrapper {
    left: 0;
    top: -24px;

    label.mat-form-field-label {
      color: #4A4A4A !important;
      font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.285rem !important;
      font-weight: normal;
      letter-spacing: inherit;
      line-height: 16px;
      opacity: 1;
      overflow: visible;
      top: 0;
      transform: none !important;
    }
  }
}
